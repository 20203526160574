<template>
    <div
        ref="backToTop"
        class="btn-effect-dark p-4 absolute top-[-20px] right-10 z-10 bg-black border-2 border-black-10 rounded-full cursor-pointer shadow"
    >
        <i class="i-mdi:arrow-top-right text-white w-5 h-5 block transform -rotate-45" />
    </div>
</template>

<script setup lang="ts">
const backToTop = ref<HTMLElement | any>(null)
onMounted(() => {
    window.addEventListener('scroll', () => {
        backToTop.value?.addEventListener('click', () => {
            document.querySelector('html')?.classList.add('scroll-smooth')
            setTimeout(() => {
                window.scrollTo(0, 0)
                setTimeout(() => {
                    document.querySelector('html')?.classList.remove('scroll-smooth')
                }, 1000)
            }, 0)
        })
    })
})
</script>

<style></style>
